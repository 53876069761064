
import React, { useState } from 'react';
import { Box, Tabs, Tab, Card, CardContent, Typography, CardActions, TextField, Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import apiClient from '../utilities/apiClient';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-searchbox';
import TemplateForm from './TemplateForm';
import useLocalStorageState from '../utilities/hooks/useLocalStorageState';


function TemplateImporter(props) {
    const { template } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [templateId, setTemplateId] = useLocalStorageState('templateId', '');
    const [jsonTemplate, setJsonTemplate] = useLocalStorageState('jsonTemplate', JSON.stringify({
        info: {
            title: ''
        },
        compositions: [
            {
                id: '',
                texts: [
                    {
                        id: '',
                        type: '',
                        description: '',
                        placeholder: '',
                    }
                ],
                files: [
                    {
                        id: '',
                        type: '',
                        description: '',
                        placeholder: '',
                    }
                ]
            }
        ]
    }, null, '\t'));

    const handleJsonTemplateChange = (value) => {
        setJsonTemplate(value);
    }

    const handleTabIndexChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleTemplateIdChange = (event) => {
        setTemplateId(event.target.value);
    }

    const handleTemplateImportClick = () => {
        const { onTemplateDataChange } = props;
        if (tabIndex === 0) {
            setIsLoading(true);
            apiClient.fetch('/api/templates/get', { templateId }).then(({ success, message, data }) => {
                if (!success) {
                    notify.error(message);
                } else {
                    onTemplateDataChange(data);
                }
            }).catch(e => {
                console.error(e);
                notify.error(e.message || e.toString());
            }).finally(() => setIsLoading(false));
        } else {
            try {
                const specs = JSON.parse(jsonTemplate);
                onTemplateDataChange({ name: specs?.info?.title, specs });
            } catch (e) {
                console.error(e);
                notify.error('Le JSON est invalide');
            }
        }
    }

    return (
        <Card>
            <CardContent>
                <Typography color="text.secondary" variant="h6" gutterBottom>
                    Sélection du template
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={tabIndex === 1 ? 6 : 3}>
                        <Box>
                            <Tabs value={tabIndex} onChange={handleTabIndexChange}>
                                <Tab label="API" />
                                <Tab label="Code" />
                            </Tabs>
                        </Box>
                        {
                            tabIndex === 0 ? (
                                <Typography variant="body2" className="mt-2">
                                    <TextField 
                                        id="outlined-basic"
                                        label="Template ID / ShortID"
                                        variant="outlined"
                                        onChange={handleTemplateIdChange}
                                        value={templateId || ""}
                                    />
                                </Typography>
                            ) : tabIndex === 1 ? (
                                <AceEditor
                                    mode="json"
                                    className="mt-2 w-full"
                                    theme="theme-github"
                                    onChange={handleJsonTemplateChange}
                                    value={jsonTemplate}
                                    editorProps={{ $blockScrolling: true }}
                                />
                            ) : null
                        }
                    </Grid>
                    <Grid item xs={12} sm={tabIndex === 1 ? 6 : 9}>
                        <div  style={{ maxHeight: '600px', overflowY: 'auto' }}>
                            {
                                template?.specs ? (
                                    <TemplateForm specs={template?.specs} />
                                ) : null
                            }
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <LoadingButton
                    disabled={(tabIndex === 0 && !templateId) || (tabIndex === 1 && !jsonTemplate)}
                    loading={isLoading}
                    loadingPosition="center"
                    variant="text"
                    onClick={handleTemplateImportClick}
                >
                    Importer
                </LoadingButton>
            </CardActions>
        </Card>
    );
}


export default TemplateImporter;

import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, AppBar, Toolbar, Button, TextField, Typography } from '@mui/material';
import useObjectState from '../utilities/hooks/useObjectState';
import apiClient from '../utilities/apiClient';
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

function NavBar(props) {
  const [logInDialogState, editLogInDialogState, resetEditLogInDialogState] = useObjectState({
    email: '',
    password: '',
    open: false,
  });
  const { loggedUser } = props;

  const openLogInDialog = () => editLogInDialogState({ open: true });

  useEffect(() => {
    apiClient.on('unauthorized', openLogInDialog);
    if (!window.firebaseAuth.currentUser) openLogInDialog();
  }, []);

  const handleLogInEmailChange = (event) => {
    const { value } = event.target;
    editLogInDialogState({ email: value });
  };
  const handleLogInPasswordChange = (event) => {
    const { value } = event.target;
    editLogInDialogState({ password: value });
  };
  const handleLogInClick = () => {
    const { email, password } = logInDialogState;
    if (email && password) {
      signInWithEmailAndPassword(window.firebaseAuth, email, password).then(() => {
        resetEditLogInDialogState();
      }).catch((e) => {
        console.error(e);
        notify.error(e.message || e.toString());
      });
    }
  };
  const handleLogOutClick = () => {
    signOut(window.firebaseAuth).catch((e) => {
      console.error(e);
      notify.error(e.message || e.toString());
    });
  };

  const handleLogInKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      handleLogInClick();
    }
  };

  return (
    <AppBar position="static" color="transparent" className="shadow">
      <Toolbar>
        <Typography className="w-full">Danim JSON GPT</Typography>
        <div className="w-full">
          {loggedUser && (
            <Button className="float-right logout-btn" onClick={handleLogOutClick} color="info">
              Logout
            </Button>
          )}
        </div>
      </Toolbar>
      {
         (logInDialogState.open || !loggedUser) && (
         <Dialog
           open
           fullWidth
           maxWidth="sm"
         >
          <DialogTitle>Login</DialogTitle>
          <DialogContent>
           <TextField
             variant="filled"
             className="w-full"
             value={logInDialogState.email}
             onChange={handleLogInEmailChange}
             onKeyPress={handleLogInKeyPress}
             label="Email"
             error={!logInDialogState.email}
           />
           <TextField
             variant="filled"
             className="w-full"
             value={logInDialogState.password}
             onChange={handleLogInPasswordChange}
             onKeyPress={handleLogInKeyPress}
             label="Password"
             type="password"
             error={!logInDialogState.password}
           />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleLogInClick}>Login</Button>
          </DialogActions>
         </Dialog>
         )
      }
    </AppBar>
  );
}

export default NavBar;

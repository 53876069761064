import React, { useEffect, useState } from 'react';
import NavBar from './components/NavBar';
import apiClient from './utilities/apiClient';
import TemplateImporter from './components/TemplateImporter';
import VideoJsonGenerator from './components/VideoJsonGenerator';
import History from './components/History';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [loggedUser, _setLoggedUser] = useState(null);
  const [appIsInitializing, setAppIsInitializing] = useState(true);
  const [templateData, setTemplateData] = useState({});
  const [remainCompletionsToLoad, setRemainCompletionToLoad] = useState(true);
  const [completionsAreFetching, setCompletionsAreFetching] = useState(true);
  const [completions, setCompletions] = useState([]);

  const setLoggedUser = user => {
    const resetUser = () => {
      apiClient.setAuthToken(null);
      _setLoggedUser(null);
      setAppIsInitializing(false);
    };
    if (!user) resetUser();
    else {
      window.firebaseAuth.currentUser.getIdToken(true).then(token => {
        apiClient.setAuthToken(token);
        _setLoggedUser(user);
        setAppIsInitializing(false);
      }).catch(e => {
        resetUser();
        console.error(e);
        notify.error(e.message || e.toString());
      });
    }
  };

  useEffect(() => {
    window.firebaseAuth.onAuthStateChanged((user) => {
      if (!user && window.openLogInDialog) window.openLogInDialog();
      setLoggedUser(user);
    });
  }, []);

  // Get a list of things for the loggedUser
  useEffect(() => {
    if (loggedUser && completionsAreFetching) {
        // Get all non loading completions
        apiClient.fetch('/api/completions/search', { limit: 20, offset: completions.length }).then(({ success, message, data }) => {
          if (!success) {
            notify.error(message);
          } else {
            if (data?.completions?.length < 20) setRemainCompletionToLoad(false);
            setCompletions([...completions, ...(data?.completions || [])]);
          }
        }).catch(e => {
          console.error(e);
          notify.error(e.message || e.toString());
        }).finally(() => setCompletionsAreFetching(false));
    }
  }, [loggedUser, completionsAreFetching]);

  // Listen scroll event to update completions
  useEffect(() => {
    const handleWindowScroll = () => {
      if (
        completionsAreFetching
        || !remainCompletionsToLoad
        || (window.innerHeight + window.scrollY < document.body.offsetHeight - 200)
      ) return;
      setCompletionsAreFetching(true);
    }

    window.addEventListener('scroll', handleWindowScroll);
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, [completionsAreFetching, remainCompletionsToLoad]);

  const handleTemplateDataChange = (data) => {
    setTemplateData(data);
  };

  const handleCompletionAdd = completion => {
    setCompletions([completion, ...completions]);
  }


  return (
    <React.Fragment>
      {
        !appIsInitializing ? (
          <React.Fragment>
            <NavBar loggedUser={loggedUser} />
            {
              loggedUser ? (
                <React.Fragment>
                  <div className="page">
                    <TemplateImporter onTemplateDataChange={handleTemplateDataChange} template={templateData} />
                    <div className="mt-2 mb-2" />
                    <VideoJsonGenerator onCompletionAdd={handleCompletionAdd} template={templateData} />
                    <div className="mt-2 mb-2" />
                    <History completionsAreFetching={completionsAreFetching} onCompletionsUpdate={setCompletions} completions={completions} />
                  </div>
                </React.Fragment>
              ) : null
            }
            <ToastContainer />
          </React.Fragment>
        ) : null
      }
    </React.Fragment>
  );
}

export default App;

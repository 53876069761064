import { useState } from 'react';

function useLocalStorageState(localStorageKey, defaultValue = null, transformer = (v) => v) {
  let localStorageValue = localStorage.getItem(localStorageKey);
  if (localStorageValue !== null) localStorageValue = transformer(localStorageValue);

  const [state, setState] = useState(localStorageValue !== null ? localStorageValue : defaultValue);

  const setStateAndSaveItInLocalStorage = (newState) => {
    localStorage.setItem(localStorageKey, newState);
    setState(newState);
  }
  
  return [state, setStateAndSaveItInLocalStorage];
}

export default useLocalStorageState;


import React from 'react';
import { CircularProgress, Box, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogContentText, Divider, Typography, Link } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TemplateForm from './TemplateForm';

function TemplateFormDialog(props) {
    const { onClose, completion } = props;
    const { completionType, video, status, error, logs, template, env, callbackError } = completion;

    const { truncateRatio, basePrompt, systemPrompt, gptRawResponse, gptUsage, url, text } = logs;
    const prompt = `SYSTEM:\n${systemPrompt}\n\nPROMPT:\n${basePrompt} << contenu utilisateur coupé à ${Math.round(truncateRatio * 100)}% >>\n\nRAW RESULT:\n${gptRawResponse}`;
    
    let timer = [];
    if (logs.timer) {
        const { scraping, gptRequest } = logs.timer;
        timer = [
            ...(scraping ? [{
                label: "Scraping",
                value: scraping,
            }] : []),
            ...(gptRequest ? [{
                label: "Requête GPT",
                value: gptRequest,
            }] : []),
        ]
    }

    return (
        <Dialog
            open
            onClose={onClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth="xl"
        >
            <DialogTitle id="scroll-dialog-title">{ video?.data?.info?.name || 'Vidéo sans titre' }</DialogTitle>
            <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                >
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Requête</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" className="mt-05" style={{ 'whiteSpace': 'pre-wrap' }}>
                                <Typography variant="caption" display="block">
                                    <strong>Sujet du template :</strong> {template.subject}
                                </Typography>
                                <Typography variant="caption" display="block" className="mt-2">
                                    <strong>Catégorie de complétion :</strong> {completionType}
                                </Typography>
                                {
                                    gptUsage?.estimatedCost ? (
                                        <Typography variant="caption" display="block" className="mt-2">
                                            <strong>Coût de la requête :</strong> {gptUsage.estimatedCost || 0} $ avec {gptUsage.model}, ({gptUsage.estimatedTokens || 0} tokens estimés, {gptUsage.totalTokens || 0} tokens consommés)
                                        </Typography>
                                    ) : null
                                }
                                {
                                    timer?.length ? (
                                        <Typography variant="caption" display="block" className="mt-2">
                                            <strong>Temps de génération :</strong> {timer.map(({ label, value }) => `${label} : ${Math.round(value/1000)}s`).join(', ')}
                                        </Typography>
                                    ) : null
                                }
                                {
                                    template?.id ? (
                                        <Typography variant="caption" display="block" className="mt-2">
                                            <strong>Template ID :</strong> <Link href={`https://bkhsfc7699n8cnpx.bo${env && env !== 'prod' ? `.${env}` : ''}.danim.com/template/list/item/${template.id}`} target="_blank">{ template.id }</Link>
                                        </Typography>
                                    ) : null
                                }
                                {
                                    video?.userId ? (
                                        <Typography variant="caption" display="block" className="mt-2">
                                            <strong>User ID :</strong> <Link href={`https://bkhsfc7699n8cnpx.bo${env && env !== 'prod' ? `.${env}` : ''}.danim.com/user/list/item/${video.userId}`} target="_blank">{ video.userId }</Link>
                                        </Typography>
                                    ) : null
                                }
                                <Divider className="mt-2" />
                                <Typography variant="caption" display="block" className="mt-2">
                                    <strong>Demande de l'utilisateur :</strong> {url ? <a href={url} target="_blank" rel="noreferrer">{url}</a> : text}
                                </Typography>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Logs</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2" className="mt-05" style={{ 'whiteSpace': 'pre-wrap' }}>
                                { prompt }
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Formulaire</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                status !== 'loading' ? (
                                    <React.Fragment>
                                        {
                                            status === 'error' && !callbackError ? (
                                                <Typography variant="body1" className="colorError">Une erreur est survenue lors de la génération du formulaire : {error}</Typography>
                                            ) : status === 'error' && callbackError ? (
                                                <Typography variant="body1" className="colorWarning">Une erreur est survenue lors de la création de la vidéo : {callbackError}</Typography>
                                            ) : null
                                        }
                                        <TemplateForm specs={template.specs} video={video} env={env} />
                                    </React.Fragment>
                                ) : (
                                    <Box sx={{ display: 'flex' }}>
                                        <CircularProgress className="m-auto" />
                                    </Box>
                                )
                            }
                        </AccordionDetails>
                    </Accordion>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}


export default TemplateFormDialog;
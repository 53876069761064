class ApiClient {
  constructor() {
    this.listeners = {};
    this.baseUrl = window.location.host === 'localhost:3000' ? 'http://localhost:5000' : '';
  }

    _getHeaders = () => ({
      'Content-Type': 'application/json',
      authtoken: this.authtoken,
    })

    _execListener = (id, args) => {
      if (this.listeners[id]) this.listeners[id](args);
    }

    on = (id, cb) => {
      this.listeners[id] = cb;
    }

    setAuthToken = token => this.authtoken = token;

    fetch = (endpoint, args = {}) => new Promise((resolve, reject) => {
      fetch(`${this.baseUrl}${endpoint}`, {
        method: 'POST',
        headers: new Headers(this._getHeaders()),
        body: JSON.stringify(args),
      }).then(res => { 
        if (res.ok) res.json().then((data) => resolve(data)).catch((e) => reject(e));
        else {
          if (res.status === 403) this._execListener('unauthorized');
          res.json().then((data) => reject({
            httpCode: res.status,
            ...data,
          })).catch((e) => reject(e));
        }
      }).catch(e => reject(e));
    });
}

export default new ApiClient();


import React, { useState, useEffect } from 'react';
import { FormControlLabel, Switch, Slider, Box, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Typography, CardActions, TextField } from '@mui/material';
import apiClient from '../utilities/apiClient';
import { LoadingButton } from '@mui/lab';
import useLocalStorageState from '../utilities/hooks/useLocalStorageState';

function VideoJsonGenerator(props) {
    const { onCompletionAdd, template } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [url, setUrl] = useLocalStorageState('url', '');
    const [templateSubject, setTemplateSubject] = useLocalStorageState('templateSubject', '');
    const [videoSubject, setVideoSubject] = useLocalStorageState('videoSubject', '');
    const [simpleText, setSimpleText] = useLocalStorageState('simpleText', '');
    const [completionType, setCompletionType] = useLocalStorageState('completionType', 'generateTextFromText');
    const [gptModel, setGptModel] = useLocalStorageState('gptModel', 'gpt-3.5-turbo');
    const [temperature, setTemperature] = useLocalStorageState('temperature', 0.1, v => parseFloat(v));
    const [ratio, setRatio] = useLocalStorageState('ratio', 1, v => parseFloat(v));
    const [createVideo, setCreateVideo] = useLocalStorageState('createVideo', false, v => v === 'true');
    const [searchImagesInStock, setSearchImagesInStock] = useLocalStorageState('searchImagesInStock', false, v => v === 'true');

    useEffect(() => {
        if (template) {
            setTemplateSubject(template.name);
        }
    }, [template]);

    const handleUrlChange = (event) => {
        setUrl(event.target.value);
    }

    const handleTemplateSubjectChange = (event) => {
        const { value } = event.target;
        setTemplateSubject(value);
    }

    const handleGptModelChange = (event) => {
        const { value } = event.target;
        setGptModel(value);
    }

    const handleCompletionTypeChange = (event) => {
        const { value } = event.target;
        setCompletionType(value);
    }

    const handleVideoSubjectChange = (event) => {
        const { value } = event.target;
        setVideoSubject(value);
    }

    const handleTemperatureChange = (event, value) => {
        setTemperature(value);
    }

    const handleRatioChange = (event, value) => {
        setRatio(value);
    }

    const handleSimpleTextChange = (event) => {
        const { value } = event.target;
        setSimpleText(value);
    }

    const handleCreateVideoChange = (event) => {
        const { checked } = event.target;
        setCreateVideo(checked);
    }

    const handleSearchImagesInStockChange = (event) => {
        const { checked } = event.target;
        setSearchImagesInStock(checked);
    }

    const handleAnalyzeClick = () => {
        setIsLoading(true);
        apiClient.fetch('/api/completions/create', { 
            template: {
                id: template.id,
                subject: templateSubject,
                specs: !template.id ? template.specs : null,
            },
            gptModel, 
            url: completionType.toLowerCase().indexOf('url') !== -1 ? url : null, 
            text: (completionType === 'scrapFromText' || completionType === 'summarizeFromText') ? simpleText : completionType === 'generateTextFromText' ? videoSubject : null, 
            completionType,
            temperature,
            ratio,
            createVideo,
            searchImagesInStock,
            returnLogs: true 
        }).then(({ success, message, data }) => {
            if (!success) notify.error(message);
            else {
                onCompletionAdd(data.completion);
                notify.info('Analyse des données en cours...');
            }
        }).catch(e => {
            console.error(e);
            notify.error(e.message || e.toString());
        }).finally(() => setIsLoading(false));
    }

    return (
        <Card>
            <CardContent>
                <Typography color="text.secondary" variant="h6" gutterBottom>
                    Analyse par l&rsquo;IA
                </Typography>
                <Typography variant="body2" className="mt-2">
                    {
                        template?.id ? (
                            <React.Fragment>
                                <FormControlLabel 
                                    control={
                                        <Switch 
                                            onChange={handleCreateVideoChange} 
                                            checked={createVideo}
                                        />
                                    } 
                                    label="Créer une vidéo ?"
                                    className="mb-2"
                                 />
                                 <div className="clearfix" />
                            </React.Fragment>
                        ) : null
                    }
                    <FormControl className="mr-1 mt-1">
                        <InputLabel id="demo-simple-select-label">Catégorie de complétion</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            value={completionType}
                            label="Catégorie de complétion"
                            onChange={handleCompletionTypeChange}
                        >
                            <MenuItem value="scrapFromUrl">HTML Scraping</MenuItem>
                            <MenuItem value="scrapFromText">Text Scraping</MenuItem>
                            <MenuItem value="summarizeFromUrl">HTML Summarize</MenuItem>
                            <MenuItem value="summarizeFromText">Text Summarize</MenuItem>
                            <MenuItem value="generateTextFromText">Text Generation</MenuItem>
                        </Select>
                    </FormControl>
                    {
                        completionType === 'scrapFromText' || completionType === 'summarizeFromText' ? (
                            <TextField 
                                className="mt-1 mr-1 mb-2 w-full"
                                label="Texte à analyser"
                                placeholder="Ecrivez un texte dans lequel se trouve les données de votre vidéo"
                                variant="outlined"
                                multiline
                                rows={4}
                                onChange={handleSimpleTextChange}
                                value={simpleText || ""}
                            />
                        ) : completionType.toLowerCase().indexOf('url') !== -1 ? (
                            <TextField 
                                className="mr-1 mt-1 mb-2"
                                label="URL à analyser"
                                placeholder="https://"
                                variant="outlined"
                                onChange={handleUrlChange}
                                value={url || ""}
                            />
                        ) : (
                            <TextField 
                                className="mt-1 mr-1 mb-2 w-full"
                                label="Sujet de la vidéo"
                                placeholder="De quel sujet souhaitez-vous parler dans cette vidéo ?"
                                variant="outlined"
                                multiline
                                rows={4}
                                onChange={handleVideoSubjectChange}
                                value={videoSubject || ""}
                            />
                        )
                    }
                    {
                        completionType.toLowerCase().indexOf('url') === -1 ? (
                            <React.Fragment>
                                <FormControlLabel 
                                    control={
                                        <Switch 
                                            onChange={handleSearchImagesInStockChange} 
                                            checked={searchImagesInStock}
                                        />
                                    } 
                                    label="Rechercher des images ?"
                                    className="mt-1 mr-1 mb-2"
                                 />
                                 <div className="clearfix" />
                            </React.Fragment>
                        ) : null
                    }
                    <div className="mt-2" />
                    <TextField 
                        className="mr-1 mb-1"
                        label="Sujet du template"
                        variant="outlined"
                        placeholder="une annonce immobilière"
                        onChange={handleTemplateSubjectChange}
                        value={templateSubject || ""}
                    />
                    <FormControl className="mr-1 mb-1">
                        <InputLabel id="demo-simple-select-label">GPT Model</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            value={gptModel}
                            label="GPT Model"
                            onChange={handleGptModelChange}
                        >
                            <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
                            <MenuItem value="gpt-4">gpt-4</MenuItem>
                            <MenuItem value="gpt-4-32k">gpt-4-32k</MenuItem>
                            <MenuItem value="auto">auto</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ width: 400 }} className="mt-2">
                        <Typography gutterBottom>
                            Température
                        </Typography>
                        <Slider
                            aria-label="Temperature"
                            defaultValue={0.1}
                            value={temperature}
                            onChange={handleTemperatureChange}
                            valueLabelDisplay="auto"
                            step={0.1}
                            marks
                            min={0}
                            max={2}
                        />
                    </Box>
                    {
                        createVideo ? (
                            <Box sx={{ width: 400 }} className="mt-2">
                                <Typography gutterBottom>
                                    Ratio de la vidéo
                                </Typography>
                                <Slider
                                    aria-label="Ratio"
                                    defaultValue={1}
                                    value={ratio}
                                    onChange={handleRatioChange}
                                    valueLabelDisplay="auto"
                                    step={0.1}
                                    min={0}
                                    max={2}
                                />
                            </Box>
                        ) : null
                    }
                </Typography>
            </CardContent>
            <CardActions>
                <LoadingButton
                    disabled={((completionType === 'scrapFromText' || completionType === 'summarizeFromText') && !simpleText) || (completionType.toLowerCase().indexOf('url') !== -1 && !url) || (completionType === 'generateTextFromText' && !videoSubject) || !templateSubject || !template?.specs}
                    loading={isLoading}
                    loadingPosition="center"
                    variant="text"
                    onClick={handleAnalyzeClick}
                >
                    { createVideo ? 'Créer' : 'Analyser' }
                </LoadingButton>
            </CardActions>
        </Card>
    );
}


export default VideoJsonGenerator;

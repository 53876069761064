
import React, { useState } from 'react';
import { Menu, MenuItem, Box, CircularProgress, Grid, Card, CardContent, Typography } from '@mui/material';
import apiClient from '../utilities/apiClient';
import TemplateFormDialog from './TemplateFormDialog';
import HistoryItem from './HistoryItem';

function History(props) {
    const { completionsAreFetching, completions, onCompletionsUpdate } = props;

    const [activeCompletionId, setActiveCompletionId] = useState(null);
    const [anchorMenu, setAnchorMenu] = useState(null);
    const [anchorMenuCompletionId, setAnchorMenuCompletionId] = useState(null);

    const handleTemplateFormDialogClose = () => setActiveCompletionId(null);

    const handleCardRightClick = (event, completionId) => {
        event.preventDefault();
        setAnchorMenu({
            left: event.clientX + 2,
            top: event.clientY - 6,
        });
        setAnchorMenuCompletionId(completionId);
    };
    const handleMenuClose = () => {
        setAnchorMenu(null);
        setAnchorMenuCompletionId(null);
    };

    const handleRemoveClick = () => {
        handleMenuClose();
        apiClient.fetch('/api/completions/delete', { id: anchorMenuCompletionId }).then(({ success, message }) => {
            if (!success) {
                notify.error(message);
            } else {
                onCompletionsUpdate(completions.filter(completion => completion.id !== anchorMenuCompletionId));
            }
        }).catch(e => {
            console.error(e);
            notify.error(e.message || e.toString());
        })
    }

    const handleCompletionUpdate = (completionId, completion) => {
        const completionIndex = completions.findIndex(c => c.id === completionId);
        if (completionIndex !== -1) {
            const _completions = [...completions];
            _completions[completionIndex] = completion;
            onCompletionsUpdate(_completions);
            return true;
        }
        return false;
    }

    const activeCompletion = completions.find(completion => completion.id === activeCompletionId);

    return (
        <React.Fragment>
            {
                activeCompletion ? (
                    <TemplateFormDialog key={activeCompletion.id} onClose={handleTemplateFormDialogClose} completion={activeCompletion} />
                ) : null
            }
            <Card>
                <CardContent>      
                    {
                        <Menu
                            anchorPosition={anchorMenu}
                            anchorReference="anchorPosition"
                            open={anchorMenu ? open : false}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleRemoveClick}>Supprimer</MenuItem>
                        </Menu>
                    }
                    <Typography color="text.secondary" variant="h6" gutterBottom>
                        Historique
                    </Typography>
                    {
                        completionsAreFetching && !completions?.length ? (
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress className="m-auto" />
                            </Box>
                        ) : (
                            <Grid container spacing={2} className="mt-1">
                                {
                                    completions.map(completion => {
                                        return (
                                            <Grid item xs={12} key={completion.id}>
                                                <HistoryItem
                                                    completion={completion}
                                                    onCardClick={setActiveCompletionId}
                                                    onCardRightClick={handleCardRightClick}
                                                    onCompletionUpdate={handleCompletionUpdate}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        )
                    }

                </CardContent>
            </Card>
        </React.Fragment>
    );
}


export default History;

import React from 'react';
import { Grid, Divider, Typography, Link } from '@mui/material';
import clsx from 'clsx';

function TemplateForm(props) {
    const { specs, video, env } = props;

    const compositions = [...(specs?.compositions || [])];
    if (specs?.info?.globals) {
        const { texts, files } = specs.info.globals;
        if (texts || files) {
            compositions.unshift({
                id: 'globals',
                description: 'Paramètres globaux',
                texts: texts || [],
                files: files || [],
            });
        }
    }

    return (
        <React.Fragment>
            {
                video?.id ? (
                    <div className="danimPlayerSection mb-2">
                        {
                            video?.shortId ? (
                                <div className="danimPlayerWrapper">
                                    <iframe src={`https://play${env && env !== 'prod' ? `.${env}` : ''}.danim.com/${video?.shortId}`} allowfullscreen="true" />
                                </div>
                            ) : null
                        }
                        <Link href={`https://bkhsfc7699n8cnpx.bo${env && env !== 'prod' ? `.${env}` : ''}.danim.com/video/list/item/${video?.id}`} target="_blank" className="danimPlayerLink">Voir la vidéo sur le back-office</Link>
                    </div>
                ) : null
            }
            {
                compositions.map((comp, compIndex) => (
                    <div key={`${comp.id}_${compIndex}`} className="w-full mt-2">
                        {/* <Typography variant="subtitle1" display="inline" className={clsx(comp.required && 'ml-1', 'colorLight', comp.disabled && 'lowOpacity')}>
                            {comp.description}
                        </Typography> */}
                        {
                            !comp.disabled && (
                                <React.Fragment>
                                    <Grid container spacing={1}>
                                        {
                                            (comp?.texts || []).map((input, inputIndex) => {
                                                const content = video?.data?.compositions?.find(videoComp => videoComp.id === comp.id)?.texts?.find(text => text.id === input.id)?.content;
                                                return (
                                                    <Grid item key={`${comp.id}_${compIndex}_${input.id}_${inputIndex}`} xs={12} sm={6} className='mt-2'>
                                                        <Typography
                                                            variant="body1"
                                                        >
                                                            {input.description}
                                                        </Typography>
                                                        {
                                                            input.placeholder && (
                                                                <Typography
                                                                    variant="caption"
                                                                >
                                                                    Par exemple : {input.placeholder}
                                                                </Typography>
                                                            )
                                                        }
                                                        {
                                                            video ? (
                                                                <Typography
                                                                    variant="body2"
                                                                    className={clsx('mt-1', 'w-bold', !input.disabled && (content === undefined || content === null) ? (input.required ? 'colorError' : 'colorWarning') : 'colorSuccess')}
                                                                >
                                                                    {content === undefined || content === null ? "NULL" : content}
                                                                </Typography>
                                                            ) : null
                                                        }
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                    <Grid container spacing={1}>
                                        {
                                            (comp?.files || []).map((input, inputIndex) => {
                                                const content = video?.data?.compositions?.[compIndex]?.files?.[inputIndex]?.content;
                                                return (
                                                    <Grid item key={`${comp.id}_${compIndex}_${input.id}`} xs={12} sm={4} lg={3} className='mt-2'>
                                                        {
                                                            // We have to remove file+ from content which is sent to Danim API
                                                            video ? (
                                                                <img className="w-full" src={(content?.toString() || "data:,").replace('file+', '')} />
                                                            ) : null
                                                        }
                                                        <Typography variant="caption">{input.description}</Typography>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </React.Fragment>
                            )
                        }
                        {
                            compIndex < compositions.length - 1 && (
                                <Divider className="mt-2" />
                            )
                        }
                    </div>
                ))
            }
        </React.Fragment>
    );
}


export default TemplateForm;
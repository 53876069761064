import '../css/index.css';
import App from './App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { toast } from 'react-toastify';
import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";

document.addEventListener('DOMContentLoaded', () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBTkBZ5WQoou4VV1valpY5VCn4kpH9qvVY",
    authDomain: "danim-json-gpt.firebaseapp.com",
    projectId: "danim-json-gpt",
    storageBucket: "danim-json-gpt.appspot.com",
    messagingSenderId: "879542312823",
    appId: "1:879542312823:web:6b6e3a6867098575b8cb72"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const firebaseAuth = getAuth(app);
  const db = getFirestore();
  if (process.env.NODE_ENV !== 'production') {
    connectAuthEmulator(firebaseAuth, "http://localhost:9099");
    connectFirestoreEmulator(db, 'localhost', 8080);
  }

  window.firestore = db;
  window.firebaseAuth = firebaseAuth;
  
  window.notify = {
    success: (msg, opts = {}) => {
      toast(msg, {
        type: toast.TYPE.SUCCESS,
        ...opts,
      });
    },
    info: (msg, opts = {}) => {
      toast(msg, {
        type: toast.TYPE.INFO,
        ...opts,
      });
    },
    warning: (msg, opts = {}) => {
      toast(msg, {
        type: toast.TYPE.WARNING,
        ...opts,
      });
    },
    error: (msg, opts = {}) => {
      toast(msg, {
        type: toast.TYPE.ERROR,
        ...opts,
      });
    },
  }

  const root = createRoot(document.getElementById('root'));
  root.render(<App />);
});

if (module.hot) {
  module.hot.dispose(() => {});
  module.hot.accept(() => {});
}

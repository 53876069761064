import React, { useEffect } from 'react';
import { doc, onSnapshot } from "firebase/firestore";
import { Grid, LinearProgress, Card, CardContent, CardActionArea, Typography, Chip } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';
import CategoryIcon from '@mui/icons-material/Category';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';


function HistoryItem(props) {
    const { onCompletionUpdate, onCardRightClick, completion, onCardClick } = props;
    const { completionType, temperature, logs, status, createdAt, template, callbackError } = completion;

    useEffect(() => {
        if (status === 'loading') {
            const unsub = onSnapshot(doc(window.firestore, 'completions', completion.id), (doc) => {
                onCompletionUpdate(doc.id, { id: doc.id, ...doc.data() });
            });

            return () => unsub();
        }
    }, [status])

    return (
            <Card>
                <CardActionArea onClick={() => onCardClick(completion.id)} onContextMenu={event => onCardRightClick(event, completion.id)}>
                    <CardContent className="p-1">
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Typography gutterBottom variant="h5" component="div" className={ status === 'error' ? (callbackError ? 'colorWarning' : 'colorError') : ''}>
                                    {
                                        status === 'error' ? (
                                            <BugReportIcon className="mr-05" sx={{ verticalAlign: "middle" }} />
                                        ) : completion.video?.id || completion.createVideo ? (
                                            <SmartDisplayIcon className="mr-05" sx={{ verticalAlign: "middle" }} /> 
                                        ) : null
                                    }
                                    {completion.video?.data?.info?.name || 'Vidéo sans titre'}
                                </Typography>
                                <Typography className="mb-1" variant="body2" color="text.secondary">
                                    {new Date(createdAt).toLocaleDateString('fr-FR', { day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit' })}, {template?.subject}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Chip
                                    className="mt-1 m-05"
                                    icon={<CategoryIcon />}
                                    label={
                                        completionType === "scrapFromUrl" ? "HTML Scraping" 
                                            : completionType === 'scrapFromText' ? 'Text Scraping' 
                                                : completionType === 'summarizeFromUrl' ? 'HTML Summarize' 
                                                    : completionType === 'summarizeFromText' ? 'Text Summarize' 
                                                        : 'Text Generation'
                                    }
                                />
                                <Chip
                                    className="mt-1 m-05"
                                    color={temperature < 0.5 ? 'primary' : temperature < 1 ? 'warning' : 'error'}
                                    icon={<DeviceThermostatIcon />}
                                    label={temperature}
                                />
                                {
                                    logs?.gptUsage?.model ? (
                                        <Chip
                                            className="mt-1 m-05"
                                            color={logs.gptUsage.model.indexOf('gpt-4') !== -1 ? 'warning' : 'primary'}
                                            variant="outlined"
                                            icon={<SmartToyIcon />}
                                            label={logs.gptUsage.model}
                                        />
                                    ) : null
                                }
                                {
                                    logs?.gptUsage?.estimatedCost ? (
                                        <Chip
                                            className="mt-1 m-05"
                                            icon={<AttachMoneyIcon />}
                                            label={Math.round(logs.gptUsage.estimatedCost * 10000) / 10000}
                                        />
                                    ) : null
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
                {
                    status === 'loading' ? (
                        <LinearProgress />
                    ) : null
                }
            </Card>
    )
}

export default HistoryItem;